import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, NoAuthGuard } from '@core/auth/guards';
import { ProfileCheckGuard } from './modules/user/guards/terms-agreement.guard';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'welcome',
    canActivate: [AuthGuard, ProfileCheckGuard],
    loadChildren: () => import('./modules/welcome/welcome.module').then(m => m.WelcomeModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'pricing',
    canActivate: [AuthGuard, ProfileCheckGuard],
    loadChildren: () => import('./modules/pricing/pricing.module').then(m => m.PricingModule),
  },
  {
    path: 'approval',
    loadChildren: () => import('./modules/approval/approval.module').then(m => m.ApprovalModule),
  },
  {
    path: 'web/support',
    loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule),
  },
  {
    path: 'company',
    loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule),
  },
  {
    path: 'cluster',
    canActivate: [AuthGuard, ProfileCheckGuard],
    loadChildren: () => import('./modules/cluster/cluster.module').then(m => m.ClusterModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'manage',
    loadChildren: () => import('./modules/management/management.module').then(m => m.ManagementModule),
  },
  {
    path: 'service',
    loadChildren: () => import('./modules/services/services.module').then(m => m.ServiceModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
  },
  {
    path: 'subscription',
    loadChildren: () => import('./modules/subscription/subscription.module').then(m => m.SubscriptionModule),
  },
  {
    path: 'account-manager',
    loadChildren: () => import('./modules/account-manager/account-manager.module').then(m => m.AccountManagerModule),
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: '**',
    canActivate: [AuthGuard, ProfileCheckGuard],
    loadChildren: () => import('./modules/welcome/welcome.module').then(m => m.WelcomeModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
